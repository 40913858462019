import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Layout from '../components/Layout/Layout';
import SEO from '../components/seo';

const H1 = styled.h1`
	font-family: 'GrotexBold';
	font-size: 28px;
	line-height: 30px;
	margin-bottom: 20px;
	text-transform: uppercase;
`;

const NotFoundPage = () => (
	<Layout>
		<SEO title="404: Not found" />
		<div
			style={{
				textAlign: 'center',
				fontWeight: 'normal',
			}}
		>
			<H1>Not Found</H1>
			<p>
				Click{' '}
				<Link to={'/'} style={{ color: 'inherit' }}>
					here
				</Link>{' '}
				to go home
			</p>
		</div>
	</Layout>
);

export default NotFoundPage;
